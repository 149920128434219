function register_fancybox_listeners() {
    $('.fancybox-link').fancybox({
        prevEffect : 'none',
        nextEffect : 'none',
        closeBtn  : true,
        arrows    : false,
        nextClick : true,
        helpers : {
            thumbs : {
                width  : 50,
                height : 50
            }
        },
        'type':'image'
    });
}

$(document).ready(function(){
    register_fancybox_listeners();

});

$(window).load(function(){
    var $wrapper = $('.creates-min-heights');
    if($wrapper.length > 0) {
        var min_height = $wrapper.find('.has-height').map(function(i, ui) {return $(ui).height(); }).toArray().reduce(function(accumulator, i) { return Math.min(accumulator, i); });
        $wrapper.find('.receives-height').height(min_height);
    }

    $wrapper = $('.creates-max-heights');
    if($wrapper.length > 0) {
        var max_height = $wrapper.find('.has-height').map(function(i, ui) {return $(ui).height(); }).toArray().reduce(function(accumulator, i) { return Math.max(accumulator, i); });
        $wrapper.find('.receives-height').height(max_height);
    }
});